import React, { Component } from "react";
import axios from "axios";
import { checkCookie, getCookie } from "../Utils/Utils";
import { Navigate } from "react-router-dom";
import { BOOTH_USER_API, USER_API, TOKEN_API } from "../../constants/Constants";
import QrReader from "react-qr-reader";
import { Container, Form, Alert, Button, Row, Card } from "react-bootstrap";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

require("./Home.scss");

class Home extends Component {
  componentDidMount() {
    if (!checkCookie("boothUserId")) {
      this.setState({ redirect: true });
    } else {
      let url = BOOTH_USER_API + getCookie("boothUserId");
      axios.get(url).then(response => {
        console.log(response.data);
        this.setState({ user: response.data });
      });
    }

    let tokenDetails = this.state.tokenDetails;
    tokenDetails.boothId = getCookie("boothId");
    this.setState({ tokenDetails });
  }

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      user: "",
      userDetails: null,
      tokenDetails: {
        boothId: "",
        userId: "",
        type: "IN-PERSON ORDER",
        tokens: 0
      },
      disabled: false,
      search: "",
      scanQR: false,
      searchView: false
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleToken = this.handleToken.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleScanQR = this.handleScanQR.bind(this);
    this.handleSearchView = this.handleSearchView.bind(this);
  }

  handleSearch(e) {
    let userId = "USER" + e.target.value;
    this.setState({ search: userId });
  }

  handleScan(e) {
    if (e) {
      console.log(e.split("?")[1]);
      const urlParams = new URLSearchParams(e.split("?")[1]);
      console.log(urlParams);
      const userId = urlParams.get("userId");
      console.log(userId);
      this.setState({ search: userId });
      this.requestSearch();
    }
  }

  handleSearchSubmit(e) {
    this.requestSearch();
  }

  requestSearch() {
    console.log(this.state.search);
    console.log(this.state.userDetails);
    this.setState({ userDetails: null });
    //let userId = "USER" + this.state.search;

    let tokenDetails = this.state.tokenDetails;
    tokenDetails.userId = this.state.search;
    this.setState({ tokenDetails });

    let url = USER_API + this.state.search;

    axios.get(url).then(response => {
      console.log(url);
      console.log(this.state.userDetails);
      console.log(response.data.userDetails);
      this.setState({ userDetails: response.data.userDetails });
      console.log("User Search");
      console.log(this.state.userDetails);
      this.setState({ scanQR: false });
    });
  }

  handleScanQR(e) {
    this.setState({
      tokenDetails: {
        boothId: "",
        userId: "",
        type: "IN-PERSON ORDER",
        tokens: 0
      },
      userDetails: null
    });
    this.setState({ searchView: false });
    this.setState({ scanQR: true });
  }

  handleSearchView(e) {
    this.setState({
      tokenDetails: {
        boothId: "",
        userId: "",
        type: "IN-PERSON ORDER",
        tokens: 0
      },
      userDetails: null
    });
    this.setState({ scanQR: false });
    this.setState({ searchView: true });
  }

  handleToken(e) {
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.tokens = e.target.value;
    this.setState({ tokenDetails });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ disabled: true });
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.boothId = getCookie("boothId");
    if (tokenDetails.tokens > 0) {
      axios
        .post(TOKEN_API, tokenDetails, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          alert("Transaction Successful!");
          this.setState({ disabled: false });
          this.setState({
            tokenDetails: {
              boothId: "",
              userId: "",
              type: "IN-PERSON ORDER",
              tokens: 0
            },
            userDetails: null
          });
        });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/authenticate" />;
    }
    const user = this.state.user;
    const userDetails = this.state.userDetails;
    return (
      <Container className="home-container">
        <Alert className="logged-user" key="primary" variant="primary">
          Booth Name: {user.boothId} <br />
          User Name: {user.firstName} {user.lastName}
        </Alert>
        <Row className="find-options">
          <Button className="scan-button" onClick={this.handleScanQR}>
            <QrCodeScannerIcon /> Scan QR
          </Button>
          <Button className="search-button" onClick={this.handleSearchView}>
            <PersonSearchIcon /> Search
          </Button>
        </Row>
        {this.state.searchView ? (
          <Form className="d-flex search-field">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Enter User Id"
              onChange={this.handleSearch}
            ></Form.Control>
            <Button variant="outline-primary" onClick={this.handleSearchSubmit}>
              <SearchOutlinedIcon />
            </Button>
          </Form>
        ) : (
          ""
        )}
        {this.state.scanQR ? (
          <div className="qr-scanner">
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          ""
        )}
        <Row className="find-results">
          {this.state.userDetails !== null && (
            <Card>
              <Card.Title className="card-title">User Information</Card.Title>
              <Card.Text className="card-text">
                Name : {userDetails.firstName + " " + userDetails.lastName}
              </Card.Text>
              <Card.Text className="card-text">
                Contact Number : {userDetails.contactNumber}
              </Card.Text>
              <div className="spacer" />
              <Card.Title className="card-title">Order Information</Card.Title>
              <Card.Text className="card-text">
                Number of tokens :{" "}
                <input
                  type="number"
                  onChange={this.handleToken}
                  value={this.state.tokenDetails.tokens}
                ></input>
              </Card.Text>
              <Button
                className="order-submit"
                onClick={this.handleFormSubmit}
                disabled={this.state.disabled}
              >
                Submit
              </Button>
            </Card>
          )}
        </Row>
      </Container>
    );
  }
}

export default Home;
