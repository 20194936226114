import React, { Component } from "react";
import axios from "axios";
import { Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HOSTURL, BOOTH_AUTH_API } from "../../constants/Constants";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
require("./Authenticate.scss");

class Authenticate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      showModal: false,
      authenticate: false,
      user: null
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleVerifyCode = this.handleVerifyCode.bind(this);
  }

  handleEmail(e) {
    this.setState({ emailAddress: e.target.value });
  }

  isValidEmail(value) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(value);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let emailAddress = this.state.emailAddress;

    if (this.isValidEmail(emailAddress)) {
      this.setState({ showModal: true });
      let url = HOSTURL + BOOTH_AUTH_API + "email/" + emailAddress;
      axios.get(url).then(response => {
        console.log(response.data);
        this.setState({ user: response.data });
      });
    }
  }

  handleVerifyCode(e) {
    if (this.state.user.authenticationCode === Number(e.target.value)) {
      //console.log("authentication code match");
      this.setState({ pathname: "/home" });
      Cookies.set("boothUserId", this.state.user.boothUserId, {
        expires: 8
      });
      Cookies.set("boothId", this.state.user.boothId, {
        expires: 8
      });
    }
  }

  render() {
    return (
      <Container className="auth-container">
        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>Verify your details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="verification-modal-text">
              Enter the authentication code sent to: {this.state.emailAddress}
            </div>
            <Form.Control type="text" onChange={this.handleVerifyCode} />
          </Modal.Body>
          <Modal.Footer>
            <Link
              to={{
                pathname: this.state.pathname,
                state: this.state
              }}
            >
              <Button variant="primary">Confirm</Button>
            </Link>
          </Modal.Footer>
        </Modal>
        <div className="title">Resonate Booth</div>
        <div className="input-group">
          <div className="input-fields">
            <Form.Control
              className="number"
              type="email"
              onChange={this.handleEmail}
              placeholder="Enter Your Email Address"
              value={this.state.emailAddress}
            />
            <Button className="button" onClick={this.handleFormSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default Authenticate;
